import { takeLatest, call, put, all } from 'redux-saga/effects';
import { message } from 'antd';
import qs from 'qs';
import i18next from 'i18next';
import api from '~/services/api';
import history from '~/services/history';
import { signInSuccess, signFailure, signOutSuccess } from './actions';
import { updateProfile } from '~/store/modules/user/actions';

export function* signIn({ payload }) {
  try {
    var data = qs.stringify({
      grant_type: 'password',
      username: payload.username,
      password: payload.password,
    });

    var client_id = 'client';
    var client_secret = '123';
    var basicAuth = 'Basic ' + btoa(client_id + ':' + client_secret);

    const response = yield call(api.post, '/oauth/token', data, { headers: { Authorization: basicAuth } });

    const { access_token } = response.data;

    api.defaults.headers.Authorization = `Bearer ${access_token}`;

    yield put(signInSuccess(access_token));

    const user = yield call(api.get, '/users/find');

    yield put(updateProfile(user.data));
    switch (user.data.roles[0].name.toString()) {
      case 'ROLE_ADMIN':
        history.push('/dashboard');
        break;
      case 'ROLE_SUBCONTRACTOR':
        history.push('/subcontractor-quotations');
        break;
      default:
        history.push('/dashboard');
        break;
    }
  } catch (error) {
    const { response } = error;
    const { status } = response;
    if (status === 400) {
      message.error(i18next.t('messages:authError'));
    }
    yield put(signFailure());
  }
}

export function* signOut() {
  try {
    yield call(api.post, 'auth/logout');
  } catch (err) {}

  yield put(signOutSuccess());
  history.push('/');
}

export function* tokenExpired() {
  try {
    yield call(api.post, 'auth/logout');
  } catch {}

  history.push('/');
  message.success('Sua sessão expirou! Faça o login novamente.');
}

function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT_REQUEST', signOut),
  takeLatest('@auth/TOKEN_EXPIRED', tokenExpired),
]);
