import axios from 'axios';

// const baseURL = 'https://votacao.animesxis.com.br/api';// baseURL: 'http://localhost:8000'
const baseURL = `${process.env.REACT_APP_BACKEND_URL}/api`;
// const baseURL = '';

const api = axios.create({
  baseURL
  });

// api.defaults.headers.common['Content-Type'] = 'application/json';
// api.defaults.headers.Accept = 'application/json';

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      return new Promise();
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
