import React, { useState, useEffect } from 'react';
import { Col, Modal, Spin, Table } from 'antd';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { optionVotes } from '~/services/hooks/votes';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const optionsGender = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  title: {
    text: 'Votos por gênero',
  },
  series: [
    {
      name: 'Gênero',
      colorByPoint: true,
      data: [],
    },
  ],
};

const optionsAge = {
  chart: {
    type: 'pie',
  },
  title: {
    text: 'Votos por Idade',
  },
  series: [
    {
      name: 'Idade',
      colorByPoint: true,
      data: [],
    },
  ],
};

const getSumByGender = data => {
  const sumByGender = [
    { name: 'Masculino', y: 0 },
    { name: 'Feminino', y: 0 },
    { name: 'Outro', y: 0 },
  ];
  data.forEach(row => {
    if (row.gender === 'M') {
      sumByGender[0].y += 1;
    }
    if (row.gender === 'F') {
      sumByGender[1].y += 1;
    }
    if (row.gender === 'O') {
      sumByGender[2].y += 1;
    }
  });
  return sumByGender;
};

const getSumByAge = data => {
  const sumByAge = [
    { name: '0-14', y: 0 },
    { name: '15-17', y: 0 },
    { name: '18-21', y: 0 },
    { name: '22-25', y: 0 },
    { name: '26-30', y: 0 },
    { name: '31+', y: 0 },
  ];
  data.forEach(row => {
    if (row.age === '0-14') {
      sumByAge[0].y += 1;
    }
    if (row.age === '15-17') {
      sumByAge[1].y += 1;
    }
    if (row.age === '18-21') {
      sumByAge[2].y += 1;
    }
    if (row.age === '22-25') {
      sumByAge[3].y += 1;
    }
    if (row.age === '26-30') {
      sumByAge[4].y += 1;
    }
    if (row.age === '31+') {
      sumByAge[5].y += 1;
    }
  });
  return sumByAge;
};

export default function Statistics({ visible, onClose, option }) {
  console.log('option', option, visible, onClose);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [genderData, setGenderData] = useState(null);
  const [ageData, setAgeData] = useState(null);

  const onCloseModal = () => {
    setTableData([]);
    setGenderData(null);
    setAgeData(null);
    onClose();
  };

  const fetchData = async () => {
    setLoading(true);
    console.log('option', option);
    if (!option?.id) return;
    const { data } = await optionVotes(option.id);

    const sumByGender = getSumByGender(data);
    optionsGender.series[0].data = sumByGender;

    const sumByAge = getSumByAge(data);
    optionsAge.series[0].data = sumByAge;

    setAgeData(optionsAge);
    setGenderData(optionsGender);
    setTableData(data);
    setLoading(false);
  };

  console.log('genderData', genderData);

  useEffect(() => {
    fetchData();
  }, [option]);

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 200,
      ellipsis: { showTitle: true },
    },
    {
      title: 'Gênero',
      dataIndex: 'gender',
      key: 'gender',
      ellipsis: { showTitle: true },
      render: gender => {
        switch (gender) {
          case 'M':
            return 'Masculino';
          case 'F':
            return 'Feminino';
          default:
            return 'Outro';
        }
      },
    },
    {
      title: 'Idade (anos)',
      dataIndex: 'age',
      key: 'age',
      ellipsis: { showTitle: true },
    },
    {
      title: 'Data',
      // dataIndex: 'create_at',
      key: 'create_at',
      ellipsis: { showTitle: true },
      render: (text, record) => {
        return moment(record.create_at).format('DD/MM/YYYY HH:mm:ss');
      },
    },
  ];

  return (
    <Modal
      width="850px"
      title="Estatísticas"
      onCancel={onCloseModal}
      open={visible}
      loading={loading}
      footer={<ModalFooter loading={loading} onCancel={onCloseModal} cancelColor="default" showOk={false} />}
    >
      <Spin spinning={loading}>
        <Table rowKey="id" bordered loading={loading} columns={tableColumns} dataSource={tableData} pagination={true} />
        <Row>
          <Col span={12}>
            {genderData?.series[0]?.data?.length > 0 && <HighchartsReact highcharts={Highcharts} options={genderData} />}
            {/* <PieChart highcharts={Highcharts} options={genderData} /> */}
          </Col>
          <Col span={12}>
            {ageData?.series[0]?.data?.length > 0 && <HighchartsReact highcharts={Highcharts} options={ageData} />}
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}
