import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n';
import { Auth0Provider } from '@auth0/auth0-react';

const domain = 'dev-dep06ynm2rsv3r7z.us.auth0.com'; // process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = 'vxtpOyjkQFpOKoeOfrsPfsapfssrgshl' // process.env.REACT_APP_AUTH0_CLIENT_ID;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      // redirectUri={window.location.origin}
      authorizationParams={{
        // redirect to admin page after login
        redirect_uri: window.location.origin + '/admin'
      }}
    >
      <App />
    </Auth0Provider>
  </I18nextProvider>,
  document.getElementById('root')
);
