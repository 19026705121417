import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import e404 from '~/pages/Errors/e404.js';
import Admin from '~/pages/Admin';
import Poll from '~/pages/Poll';

export default function Routes() {
  return (
    <Switch>
      <Redirect exact from="/" to="/poll" />
      <Admin path="/admin" />
      <Poll path="/poll" />
      <Route path="*" component={e404} />
    </Switch>
  );
}
