import React from 'react';
import { Container } from './styles';

export default function Footer() {

  return (
    <Container>
      <p>All rights reserved</p>
    </Container>
  );
}
