import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';

const endPoint = 'banner';

// FIND ALL
export const findAllBanners = async () => {
  try {
    const { data } = await api.get(`/${endPoint}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND BY ID
export const findBannerById = async (id) => {
    try {
      const { data } = await api.get(`/${endPoint}/${id}`);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };

// INSERT IMAGE
export const uploadBannerImage = async (id, image) => {
  const formData = new FormData();
  formData.append("file", image);
  try {
    const { data } = await api.post(`/${endPoint}/img/${id}`, formData);
    message.success('Imagem inserida com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createBanner = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/`, values);
    message.success('Banner criado com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateBanner = async (id, values) => {
  try {
    console.log('values updateBanner', id, values);
    const { data } = await api.post(`/${endPoint}/update/${id}`, values);
    message.success('Banner atualizado com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteBanner = async id => {
  try {
    const { data } = await api.post(`/${endPoint}/delete/${id}`);
    message.success('Banner excluído com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

findAllBanners.propTypes = {
  filters: PropTypes.object.isRequired,
};

createBanner.propTypes = {
  values: PropTypes.object.isRequired,
};

updateBanner.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteBanner.propTypes = {
  id: PropTypes.string.isRequired,
};
