import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return !isAuthenticated && (
    <Button type="primary" icon={<LoginOutlined />}
     onClick={() => loginWithRedirect()} >
      Log in
    </Button>
  );
}

export default LoginButton;