import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Banner({ isLoading, setFilters }) {
  const handleSearch = values => {
    if (values.nameFilter === '0') {
      values.nameFilter = '';
    }

    const filter = [];
    try {
      if (values.nameFilter !== undefined && values.nameFilter !== null && values.nameFilter !== '') {
        filter.push({
          field: 'name',
          value: values.nameFilter,
          restriction: 'LIKE',
        });
      }

      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Box>
      <Formik initialValues={{ nameFilter: '', areaFilter: 'All' }} enableReinitialize onSubmit={handleSearch}>
        {({ errors, resetForm }) => (
          <Spin spinning={isLoading}>
            <Form>
              <Row>
                <Col span="12">
                  <FormControl error={errors.nameFilter} field="nameFilter" label="Descrição do Banner">
                    <Input name="nameFilter" placeholder="Entre com a descrição" />
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  Limpar
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  Buscar
                </Button>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
