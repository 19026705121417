import React, { useEffect, useState } from 'react';
import { Breadcrumb, Space, Tooltip, Carousel, Skeleton, Card, List, Typography } from 'antd';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import DefaultLayout from '~/pages/_layouts/full';
import { BreadcrumbCustom } from '~/styles/global';
import { findAllPolls } from '~/services/hooks/polls';
import { findAllBanners } from '~/services/hooks/banners';
import ViewResult from './ViewResult';
import VoteForm from './VoteForm';
import PollImgDiv from './pollImgDiv';
import './styles.css';

const { Paragraph } = Typography;

export default function Poll() {
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showVoteForm, setShowVoteForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    setLoading(true);
    findAllBanners().then(response => {
      // filter only active banners
      response.data = response.data.filter(banner => banner.active === 1);
      // order by position asc
      response.data.sort((a, b) => a.position - b.position);
      setBanners(response.data);
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    findAllPolls().then(response => {
      setTableData(response.data);
      // order by created_at desc and get 3 last open polls
      // const lastOpenPolls = response.data
      //   .filter(poll => poll.active === 1)
      //   .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      //   .slice(0, 4);
      // setLastOpenPolls(lastOpenPolls);
    });
    setLoading(false);
  }, []);

  const handleViewResults = record => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  const handleVote = record => {
    setSelectedRecord(record);
    setShowVoteForm(true);
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item href="/">Enquetes</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title="ENQUETES ANIME X" />
      <Carousel autoplay>
        {banners.map((banner, index) => (
          <div>
            <div class="main">
              <div class="container">
                <img
                  key={index}
                  alt={`Banner ${banner.id}`}
                  src={`${process.env.REACT_APP_BACKEND_URL}/images/banners/banner_${banner.id}.jpg?`}
                  width="100%"
                />
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <Box>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 5,
          }}
          style={{ display: 'flex', flexDirection: 'column' }}
          dataSource={tableData}
          renderItem={item => (
            <Skeleton loading={loading} active={true} title={true} shape="square">
              <List.Item>
                <Card
                  // title={<Text strong style={ {textAlign: 'center'}   }>{item?.name}</Text>}
                  bodyStyle={{
                    textAlign: 'center',
                    backgroundColor: '#f5f5f5',
                  }}
                  hoverable
                  cover={<PollImgDiv pollId={item?.id} />}
                  onClick={() => {
                    item.active === 0 ? handleViewResults(item) : handleVote(item);
                  }}
                >
                  <div style={{ textAlign: 'center', minHeight: 60, maxHeight: 60 }}>
                    <Paragraph
                      ellipsis={{
                        tooltip: true,
                        rows: 2,
                        expandable: false,
                      }}
                      style={{ textAlign: 'center', fontSize: '1.2rem', color: '#333333' }}
                    >
                      {item?.name}
                    </Paragraph>
                  </div>
                  <div style={{ textAlign: 'center', paddingTop: 5 }}>
                    <Space size="middle">
                      {item.active === 0 ? (
                        <Tooltip title="Ver resultados">
                          <img
                            src="assets/images/result.png"
                            alt="Ver resultados"
                            // onClick={() => handleViewResults(item)}
                            width="100px"
                            style={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Votar">
                          <img
                            src="assets/images/voteButton.png"
                            alt="Votar"
                            // onClick={() => handleVote(item)}
                            width="100px"
                            style={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      )}
                    </Space>
                  </div>
                </Card>
              </List.Item>
            </Skeleton>
          )}
        />
        <ViewResult selectedRecord={selectedRecord} visible={showModal} onClose={() => setShowModal(false)} />
        <VoteForm selectedRecord={selectedRecord} visible={showVoteForm} onClose={() => setShowVoteForm(false)} />
      </Box>
    </DefaultLayout>
  );
}
