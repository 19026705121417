import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import Footer from '~/pages/_partials/Footer';
import { Container, Content } from './styles';
import Header from '~/pages/_partials/Header';
import Logo from '~/components/Logo';

export default function DefaulLayout({ breadcrumb, children, userInfo }) {
  const [windowWidth, setWindowWidth] = useState(null);

  const sizeOfThings = () => {
    var windowWidth = window.innerWidth;
    setWindowWidth(windowWidth);
  };

  window.addEventListener('resize', function() {
    sizeOfThings();
  });

  useEffect(() => {
    sizeOfThings();
  }, []);

  return (
    <Container id="fullLayout" className="layout">

      <Header style={{ position: 'fixed', zIndex: 1, width: '100%', height: '87px !important'
     }} userInfo={userInfo} >
        {windowWidth < 500 ?
          <Logo logoName="LogoWhite.png" width="150" height="87" />
        :<Logo logoName="LogoWhite.png" width="150" height="87" />
        }
      </Header>
      <Layout>
        <Layout style={{ padding: '0 24px 24px' }}>
          {breadcrumb}
          <Content
            className="site-layout-background"
            style={{ padding: 0, margin: 0, minHeight: 280 }}
          >
            {children}
          </Content>
          <Footer/>
        </Layout>
      </Layout>
    </Container>
  );
}

DefaulLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
