import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();

  return isAuthenticated && <Button type="primary" icon={<LogoutOutlined />}
  onClick={() => logout()} >Log out</Button>;
}

export default LogoutButton;