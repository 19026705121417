import React, { useEffect, useState } from 'react';
import { findPollById } from '~/services/hooks/polls';
import { Avatar } from 'antd';

export default function PollImgDiv({ pollId }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    findPollById(pollId).then(response => {
      setOptions(response.data.options);
    });
  }, [pollId]);

  return (
    <Avatar
      style={{ height: '150px' }}
      shape="square"
      src={`${process.env.REACT_APP_BACKEND_URL}/images/${options[0]?.id}.jpg?${performance.now()}`}
    />
  );
}
