import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';


const endPoint = 'vote';

// FIND ALL
export const findAllVotes = async () => {
  try {
    const { data } = await api.get(`/${endPoint}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET VOTES BY OPTION
export const optionVotes = async (id) => {
  try {
    const { data } = await api.get(`/optionvotes/${id}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND BY ID
export const findVoteById = async (id) => {
    try {
      const { data } = await api.get(`/${endPoint}/${id}`);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };

// CREATE
export const createVote = async values => {
  console.log('vote data values', values);
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    message.success('Voto Inserido com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateVote = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/update`, values);
    message.success('Votação atualizada com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteVote = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/delete/${id}`);
    message.success('Votação excluída com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

findAllVotes.propTypes = {
  filters: PropTypes.object.isRequired,
};

createVote.propTypes = {
  values: PropTypes.object.isRequired,
};

updateVote.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteVote.propTypes = {
  id: PropTypes.string.isRequired,
};

findVoteById.propTypes = {
  id: PropTypes.string.isRequired,
};

optionVotes.propTypes = {
  id: PropTypes.string.isRequired,
};
