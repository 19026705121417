import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';

const endPoint = 'option';

// FIND ALL
export const findAllOptions = async () => {
  try {
    const { data } = await api.get(`/${endPoint}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND BY ID
export const findOptionById = async (id) => {
    try {
      const { data } = await api.get(`/${endPoint}/${id}`);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };

// INSERT IMAGE
export const uploadOptionImage = async (id, image) => {
  const formData = new FormData();
  formData.append("file", image);
  try {
    const { data } = await api.post(`/${endPoint}/img/${id}`, formData);
    message.success('Imagem inserida com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createOption = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/`, values);
    message.success('Opção criada com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateOption = async (id, values) => {
  try {
    const { data } = await api.post(`/${endPoint}/update/${id}`, values);
    message.success('Opção atualizada com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteOption = async id => {
  try {
    const { data } = await api.post(`/${endPoint}/delete/${id}`);
    message.success('Opção excluída com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

findAllOptions.propTypes = {
  filters: PropTypes.object.isRequired,
};

createOption.propTypes = {
  values: PropTypes.object.isRequired,
};

updateOption.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteOption.propTypes = {
  id: PropTypes.string.isRequired,
};
