import { Layout } from 'antd';
import styled from 'styled-components';

export const Container = styled(Layout.Header)`
  padding: 0 14px 0 24px;
  height: 87px;

`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  // color: #fff;
  font-size: 14px;
  font-weight: 600;
  .anticon {
    margin-right: 10px;
  }
`;
