import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';


const endPoint = 'pool';

// FIND ALL
export const findAllPolls = async () => {
  try {
    const { data } = await api.get(`/${endPoint}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND BY ID
export const findPollById = async (id) => {
    try {
      const { data } = await api.get(`/${endPoint}/${id}`);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };

// CREATE
export const createPoll = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/`, values);
    message.success('Votação criada com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updatePoll = async (id, values) => {
  try {
    const { data } = await api.post(`/${endPoint}/update/${id}`, values);
    message.success('Votação atualizada com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deletePoll = async id => {
  try {
    const { data } = await api.post(`/${endPoint}/delete/${id}`);
    message.success('Votação excluída com sucesso!');
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

findAllPolls.propTypes = {
  filters: PropTypes.object.isRequired,
};

createPoll.propTypes = {
  values: PropTypes.object.isRequired,
};

updatePoll.propTypes = {
  values: PropTypes.object.isRequired,
};

deletePoll.propTypes = {
  id: PropTypes.string.isRequired,
};
