import React from 'react';
import PropTypes from 'prop-types';
import { Container, UserInfo } from './styles';
import { Col, Row } from 'antd';

export default function Header({ children, userInfo }) {
  return (
    <Container className="header">
      <Row>
        <Col span={12}>
          <div className="logo">{children}</div>
        </Col>
        <Col span={12}>
          <UserInfo>{userInfo}</UserInfo>
        </Col>
      </Row>
    </Container>
  );
}

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
